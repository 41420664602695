.background {
    background: linear-gradient(288deg, #FFB6B9 0%, #FFB6B9 35%, #FAE3D9 calc(35% + 1px), #FAE3D9 45%, #BBDED6 calc(45% + 1px), #BBDED6 65%, #61C0BF calc(65% + 1px), #61C0BF 100%);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.scrollText {
    font-size: 10rem;
    font-weight: 800;
    color: #000;
    width: 60%;
    text-align: left;
    line-height: 10rem;
    letter-spacing: -5px;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    height: 35rem;
    width: 35%;
    text-align: center;
    border-radius: 10px;
    padding: 2rem !important;
}

.card1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    height: 35rem;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    padding: 2rem !important;
}

.landingImg {
    width: 100%;
    border-radius: 1rem;
}

.parallax {
    margin-right: 10%;
}

.sticky {
    margin-left: 10%;
    background-color: #ff6d6d;
}

.scrollUpbg {
    background: linear-gradient(56deg, rgb(255, 180, 172) 0%, rgb(255, 180, 172) 40%, rgb(103, 145, 134) calc(40% + 1px), rgb(103, 145, 134) 50%, rgb(38, 78, 112) calc(50% + 1px), rgb(38, 78, 112) 70%, rgb(255, 235, 211) calc(70% + 1px), rgb(255, 235, 211) 100%)    
}

.blue {
    background-color: #5b65ad;
    color: #000;
}

.purple {
    background-color: #9d65d0;
}

.orange {
    background-color: #dd9241;
}

@media (max-width: 750px) {
    .card {
        width: 40%;
    }

    .parallax {
        margin-right: 1.5rem;
    }

    .sticky {
        margin-left: 1.5rem;
    }
}