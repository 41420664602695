* {
  padding: 0 !important;
  font-family: 'Gilroy-Bold', sans-serif !important;
}

@font-face {
  font-family: 'Gilroy-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('/Gilroy-Bold'), url('Gilroy-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('/Gilroy-Regular'), url('Gilroy-Regular.woff') format('woff');
}